const ConfigHelper = {
  getCountryCode: function () {
    const countryCode = process.env.REACT_APP_COUNTRY_CODE;
    return countryCode;
  },
};
export default ConfigHelper;

export const disableConsoleLogs = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log = () => {};
  }
};