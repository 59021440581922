import MicroserviceFetchUtil from "../common/microservice-fetch-util";
import MicroservicePostFetchUtil from "../common/microservice-post-util";
import { getIsNonSSOUser, getIsVAPTEnabled } from "../helper/vaptHelper";
import { getCookie } from "../utils/utils";

export const checkActiveStatus = async (setIsSessionAllowed, setIsPasswordExpired) => {
    try {
      const apiResponse = await MicroservicePostFetchUtil.get(`sessions/v1/checkActiveStatus`, {}, 'session');            
        if(apiResponse.statusCode === 200) {
            const sessionResponse = apiResponse["response-data"];
            setIsPasswordExpired(sessionResponse.passwordExpired);                
            setIsSessionAllowed(sessionResponse.sessionAllowed);
        } else {
            console.warn(apiResponse.statusMessage, apiResponse.errors);
        }
    } catch (error) {
        console.log(error);
    }
}

export const storeSessionAPIService = async (selectedCountryCode, userID, userAgent, loginType) => {
    const customerToken = getCookie("idToken");
    const body = {
        "username": userID,
        "deviceId": userAgent,
        "loginType": loginType,
        "token": customerToken
    }
    if(getIsVAPTEnabled(selectedCountryCode)) {
        try {
            const response = await MicroserviceFetchUtil.post(
                'sessions/v1/storeSession',
                { countryCode: selectedCountryCode },
                body
            );

            if (response.statusCode === 200) {
                console.log(response['response-data'].message);
            } else {
                console.warn(response.statusMessage, response.errors);
            }
        } catch (error) {
            console.error(error.message);
        }
    }
}

export const refreshTokenAPIService = async (countryCode, oldToken, newToken) => {
    const body = {
        "oldToken": oldToken,
        "newToken": newToken
    }
    if(getIsVAPTEnabled(countryCode) && getIsNonSSOUser()) {
        try {
            const response = await MicroserviceFetchUtil.put(
                `sessions/v1/refreshToken`,
                {countryCode: countryCode},
                {},
                body
            )
            localStorage.setItem("VAPTResponse", response);
            if (response.statusCode === 200) {
                console.log(response['response-data'].message);
            } else {
                console.warn(response.statusMessage, response.errors);
            }
        } catch (error) {
            console.error(error.message);
        }
    }
}

export const logoutService = async (countryCode) => {
    if(getIsVAPTEnabled(countryCode) && getIsNonSSOUser()) {
        try {
            const response = await MicroservicePostFetchUtil.get(
                'sessions/v1/logout',
                {
                    countryCode: countryCode,
                    customerToken: getCookie("idToken"),
                }
            );
            if (response.statusCode === 200) {
                console.log(response['response-data'].message);
            } else {
                console.warn(response.statusMessage, response.errors);
            }
        } catch (error) {
            console.error(error.message);
        }
    }
}

export const forgotPasswordService = async (userID, hashedPassword, countryCode) => {
    const body = {
        "username": userID,
        "encryptedPassword": hashedPassword
    };
    if(getIsVAPTEnabled(countryCode)) {
        try {
            const response = await MicroserviceFetchUtil.post(
                'sessions/v1/forgotPassword',
                {
                    countryCode: countryCode
                },
                body
            );
            if (response.statusCode === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error.message);
        }
    } else {
        return true;
    }
}