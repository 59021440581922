const OutletGeoCompliance = {
    OGCompliance_Header: 'Outlet Geo-Compliance',
    OGCompliance_Activity_Log: 'Activity Log',
    OGCompliance_Export_Outlets: 'Export Outlets',
    OGCompliance_Bulk_Update: 'Bulk Update Geo-Compliance status',
    Search: 'Search',
    Enable_Modal_Title: "Enable Outlet level geo-compliance check configuration",
    Enable_Modal_SubTitle: "Once switched on, Geo compliance check bypass will permanently be set at outlet level and market will not be able to configure Geo compliance check at SM level.",
    Export_Outlet_Details: "Export Outlet Details",
    Activity_Log_Subheader: "Log of all files uploaded with Outlet Geo-Complaince",
    Info_Message: "A maximum of",
    Ten_Outlet_Codes: "10 outlet codes",
    Info_Message1: "can be searched by comma separated values",
    OutletId: "Outlet ID",
    SaveHeaderOGCompliance: "Geo-Compliance change",
    SaveMessageOGCompliance: "You are changing the Outlet geo-complaince setting, Are you sure you want to continue?",
    OG_Update_Modal_Title: "Change Outlet Status",
    UploadSuccessOGCompliance:  "Outlet Geo-Compliance Status updated successful",
    MB25: "25MB",
    SomethingWentWrong: "Something went wrong. Please try again after sometime"

}

export default OutletGeoCompliance;