export default {
    Legend: "Legend",
    Complete: "Complete",
    Pending: "Pending",
    CompletionSent: "Sent for Completion",
    Rejected: "Rejected",
    DateRestrictionMsg: "The time window between the start date and the end date is restricted to maximum 60 calendar days.",
    DateRestrictionMsg1: "Data Availability: 1st Oct 2023 onwards till current date. To access data prior to this period, please raise a Service Request!",
    CountryLevelDTMsg1: "The data shown in the report is restricted only to the DTs you have access to.",
    CountryLevelDTMsg2: "This report is at country level(coupon1.0), hence no DT level access applicable",
    Filters: "Filters",
    ClearAll: "Clear All",
    DownloadReport: "Download Report",
    DownloadText: "Please click on the link below to download the",
    Report: "report",
    DownloadMsg1: "Preparing the file for download...",
    DownloadMsg2: "please do not close this window",
    DownloadError: "Download Error",
    DownloadErrorMsg: "Something went wrong while downloading the file. Please try again",
    Downloading: "File is Downloading",
    DownloadSuccess: "Report Download successful",
    Apply: "Apply",
    Download: "Download",
    DownloadRowCountMsg: "Download restricted to first 100000 records only.",
    TaskCompletionStatus: "Task Completion Status"
}